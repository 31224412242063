import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ConfigService } from "../../core/services/config.service";
import { WebSocketService } from "../../core/services/web-socket.service";
import { RouterService } from "../../core/services/router.service";
import { AuthService } from "../../core/services/auth.service";
import { AgentModel } from "../../core/models/project";
import { AnalyzingDocument } from "../../core/models/analyzingDocument";
import { DocumentAnalysisService } from "../../core/services/document-analysis.service";

@Injectable({
    providedIn: 'root'
})
export class AgentGuard implements CanActivate {

    constructor(
        private router: RouterService,
        private config: ConfigService,
        private socket: WebSocketService,
        private auth: AuthService,
        private documentAnalysis: DocumentAnalysisService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const agentId = this.getAgentIdFromRoute(route);

        if (!agentId || !this.isAgentIdValid(agentId)) {
            this.router.goToNotFound();
            return false;
        }

        this.config.setAgentId(agentId);

        try {
            const agentConfig = await this.loadAgentConfiguration();

            if (!agentConfig.authenticationEnabled) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
            }

            if (agentConfig.authenticationEnabled && !(await this.auth.isLogged())) {
                this.router.goToSignIn();
                return false;
            }

            this.config.setAgentConfig(agentConfig);

            const promises: Promise<any>[] = [];

            if (agentConfig.authenticationEnabled) {
                promises.push(this.loadDocumentsForAnalysis());
            }

            promises.push(this.initializeWebSocketConnection());

            await Promise.all(promises); // Paralelize promises
            return true;
        } catch (error) {
            console.error('Error during configuration setup:', error);
            this.router.goToNotFound();
            return false;
        }
    }

    private getAgentIdFromRoute(route: ActivatedRouteSnapshot): string | null {
        return route.url[0]?.path || null;
    }

    private isAgentIdValid(agentId: string): boolean {
        return this.config.getAgentId() === agentId || !!agentId;
    }

    private async loadAgentConfiguration(): Promise<AgentModel> {
        const configResponse = await this.config.fetchConfiguration();
        return configResponse.body as AgentModel;
    }

    private async loadDocumentsForAnalysis() {
        const documentResponse = await this.documentAnalysis.getDocumentsToAnalyse();

        if (documentResponse.status === 200) {
            this.documentAnalysis.setAnalyzingDocuments(documentResponse.body);
        }
    }

    private async initializeWebSocketConnection(): Promise<boolean> {
        try {
            await this.socket.initConnection();
            return true;
        } catch (error) {
            console.error('Error initializing WebSocket connection:', error);
            this.router.goToNotFound();
            return false;
        }
    }
}
